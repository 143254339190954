import { getImageFields, getLinkFields } from ".";

export default (contentType: string) => `
  ... on ${contentType}_Pagelayout_FlexibleContent_BioCards {
            title
            bioCards {
                wysiwyg
                email
                jobTitle
                name
                ${getImageFields()}
              }

   }
`;
