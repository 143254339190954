import { getButtonGroupFields } from ".";

export default (contentType: string) => `
    ... on ${contentType}_Pagelayout_FlexibleContent_SubNavigation {
        addButtons
        ${getButtonGroupFields()}
        richText {
            columnOffset
            wysiwyg
        }
        subdomain
        title
        links {
            link {
                url
                title
            }
        }
    }
`;