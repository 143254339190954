
export default (contentType: string) => `
    ... on ${contentType}_Pagelayout_FlexibleContent_Form {
        sender
        tabs {
            formInfo {
                heading
                subheading
            }
            rows {
                title
                fields {
                    title
                    type
                    placeholder
                    required
                    validation
                    choices {
                        choice
                    }
                }
            }
        }
    }
`