import { getButtonGroupFields, getLinkFields } from ".";

const query = (contentType: string) => `
  ... on ${contentType}_Pagelayout_FlexibleContent_RichTextCta {
              richText {
                columnOffset
                wysiwyg
              }
              addButtons
              ${getButtonGroupFields()}
            }
`;

export default query;
