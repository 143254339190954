const getTextComponentFields = (fieldName = "textComponent") => `
  ${fieldName} {
    title
    titleCategory
    subtitles {
        subtitles
    }
    includeButton
    buttons {
        buttonColor
        style
        link {
          target
          title
          url
        }
    }
  }
`;
export default getTextComponentFields;
