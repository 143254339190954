import { getImageFields } from ".";

export default (contentType: string) => `
    ... on ${contentType}_Pagelayout_FlexibleContent_Table {
        sectionHeading
        table {
            headings {
                heading
            }
            rows {
                column1
                column2 {
                    wysiwyg
                    ${getImageFields()}
                }
            }
        }
    }
`;