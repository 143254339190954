import { getImageFields, getLinkFields } from ".";

export default (contentType: string) => `
  ... on ${contentType}_Pagelayout_FlexibleContent_Accordions {
        accordions {
            title
            descriptions {
              wysiwyg
            }
        }
   }
`;
