/** @format */

import { Box } from '@chakra-ui/layout';
import { NextSeo } from 'next-seo';
import { useEffect, useState } from 'react';
import Layout from '../../../../components/layout';
import getFooter from '../../../../lib/api/acf/footer';
import { getContentNode } from '../../../../lib/api/contentNodes';
import { getSeoFields } from '../../../../lib/getSeoFields';
import Patterns from '../../../../patterns';
import mappingFooter from '../../../../patterns/Footer/mappingFooter';
// import getMainMenu from '../../../../lib/api/acf/mainMenu';
import { GetStaticPaths } from 'next';
import DraftModeUI from '../../../../components/DraftModeUI/Index';
import {
	getAllPagesForAllSubdomains
} from '../../../../lib/api';
import {
	getGlobalMainMenu, getMainMenu, getParentSiteMainMenu
} from '../../../../lib/api/acf/navBarMenu';
import mappingNavBar from '../../../../patterns/NavBar/mappingNavBar';
import { identifySubdomain } from '../../../../utils';

function Index({post, seo, navBar, footer, subdomain, draftMode}) {
	if (!post) return null;

	const [backupPost, setBackupPost] = useState(null);

	useEffect(() => {
		//if the post is not found, fetch it from the wpgql api and use it to render the page
		if (!post) {
			// console.log('Retrying...');
			(async () => {
				console.log('Post not found, fetching from wpgql api');
				//get the current slug from the url
				const url = window.location.href;
				//get the full slug from the url some slugs might be /parent/child
				const id = url.split(process.env.ROOT_DOMAIN)[1];
				// console.log('url split for id: ', url.split(process.env.ROOT_DOMAIN));

				// console.log('ID for getContentNode - ', id);
				// console.log('subdomain for getContentNode - ', subdomain);
				const idType = 'URI';
				const post = await getContentNode(id, idType, false, subdomain);
				if (!post) {
					console.log('No post found for ', id, idType, false, subdomain);
				} else {
					console.log('Post found for ', id, idType, false, subdomain);
					// console.log('Post data: ', post);
					//set the backup post to the post from the wpgql api
					setBackupPost(post);
				}
			})();
		}
	}, [post]);

	if (!post && !backupPost) {
		return <Box>Loading post...</Box>;
	}
	if (backupPost) {
		post = backupPost;
	}

	return (
		<Layout seo={seo} navBar={navBar} footer={footer} subdomain={subdomain}>
			<NextSeo {...seo} />
			<Box>
				{draftMode && <DraftModeUI />}
				{post.pageLayout.flexibleContent.map((post: any, index: number) => {
					return (
						<Patterns
							fieldGroupName={post.__typename}
							post={post}
							subdomain={subdomain}
							key={`${post.fieldGroupName}_${index}`}
						/>
					);
				})}
			</Box>
		</Layout>
	);
}

export const getStaticPaths: GetStaticPaths = async () => {
	const allPages = await getAllPagesForAllSubdomains();

	// console.log(allPages);
	// console.log('pages _sites [site] posts [slug] allPosts: ', allPosts);
	return {
		paths: allPages,
		fallback: true,
	};
};

export async function getStaticProps(context) {
	let {params: {slug, site}, draftMode} = context;

	const subdomain = site || '';

	const id = `${slug}`;
	const idType = 'URI';
	const post = await getContentNode(id, idType, draftMode, subdomain);
	if (!post) {
		console.log('No post found for ', id, idType, draftMode, subdomain);
	} else {
		console.log('Post found for ', id, idType, draftMode, subdomain);
	}
	if (!post) return {notFound: true};

	const seo = !draftMode ? getSeoFields(post) : null;

	// Menus
	const globalMainMenu = await getGlobalMainMenu('');
	const mainMenu = await getMainMenu(subdomain);
	const parentSiteMenu = await getParentSiteMainMenu('');

	let navBar: any = [];

	if (mainMenu && globalMainMenu && parentSiteMenu) {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		navBar = mappingNavBar({globalMainMenu, parentSiteMenu, mainMenu});
	}

	let footer = await getFooter(subdomain);
	footer = mappingFooter(footer);

	const subdomainStr = identifySubdomain(post?.link);
	if(!draftMode) {
		draftMode = false;
	}

	return {
		props: {post, seo, navBar, footer, subdomain: subdomainStr, draftMode},
		revalidate: 150,
	};
}

export default Index;
