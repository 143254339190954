/** @format */

import {gql} from '@apollo/client';
import {getApolloClient} from './apollo-client';

export const getContentTypes = async (first: number = 2000) => {
	// TODO: Remove hardcode - pass subdomain from template
	const subdomain = '';
	const client = getApolloClient(subdomain);

	try {
		const {data} = await client.query({
			query: gql`
        query getContentTypes {
          contentTypes {
            nodes {
              name
              graphqlPluralName
              graphqlSingleName
              ${
								first !== undefined
									? `
                contentNodes(first: ${first}) {
                  nodes {
                    uri
                  }
                }
              `
									: `
              contentNodes(first: 10000) {
                nodes {
                  uri
                }
              }
              `
							}
            }
          }
        }
      `,
		});

		return data.contentTypes.nodes;
	} catch (e) {
		console.error(e);
		console.error(`[contentTypes][getContentTypes]: ${e.message}`);
		throw e;
	}
};
