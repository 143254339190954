import { getImageFields, getLinkFields } from ".";

export default (contentType: string) => `
  ... on ${contentType}_Pagelayout_FlexibleContent_Grid{
        title
        gridList {
            ${getImageFields()}
            title
            wysiwyg
        }
   }
`;
