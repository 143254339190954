export default (contentType: string) => `
    ... on ${contentType}_Pagelayout_FlexibleContent_RoomCarousel {
        showSectionHeading
        sectionHeading
        roomRepeater {
            tabLabel
            title
            subtitle
            description
            image {
                sourceUrl
                altText
                mediaDetails {
                    height
                    width
                }
            }
        }
    }
`;
