import { getImageFields, getLinkFields } from ".";

export default (contentType: string) => `
  ... on ${contentType}_Pagelayout_FlexibleContent_CtaBanner {
              title
              subtitles {
                subtitles
              }
              backgroundGroup {
                backgroundType
                ${getImageFields()}
                backgroundColour {
                  backgroundColour
                }
              }
              displayFullBleed
              ctas {
                form
                formName
                buttonGroup {
                  buttonColor
                  style
                  ${getLinkFields()}
                }
              }

   }
`;
