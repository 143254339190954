import { getImageFields, getLinkFields } from ".";

export default (contentType: string) => `
  ... on ${contentType}_Pagelayout_FlexibleContent_CtaCards {
            fieldGroupName
            title
            cards {
                cardRepeater {
                    title
                    includeButton
                    showLogo
                    backgroundGroup {
                        backgroundType
                        ${getImageFields()}
                        backgroundColour {
                            backgroundColour
                        }
                    }
                    ${getLinkFields()}
                    buttonGroup {
                        buttonColor
                        style
                        ${getLinkFields()}
                    }
                }
            }
   }
`;
