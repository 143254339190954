import { getImageFields } from ".";
import getTextComponentFields from "./textComponentFields";

const query = (contentType: string) => `
  ... on ${contentType}_Pagelayout_FlexibleContent_Imagetext {
    textAlignment
    imageGroup {
        shieldLogo
        ${getImageFields()}
    }
     ${getTextComponentFields()}
  }
`;

export default query;
