export default (contentType: string) => `
  ... on ${contentType}_Pagelayout_FlexibleContent_HeroBanner {
              fieldGroupName
              subtitle
              title
              titleCategory
              fullwidth
              background
              backgroundImage {
                altText
                mediaDetails {
                  height
                  width
                }
                sourceUrl
              }
              backgroundColorGroup {
                backgroundColour
                fieldGroupName
              }
              backgroundVideoGroup {
                videoThumbnail {
                  altText
                  mediaDetails {
                    width
                    height
                  }
                  sourceUrl
                }
                video {
                  altText
                  mediaItemUrl
                  mediaDetails {
                    height
                    width
                  }
                }
              }
            }

`;
