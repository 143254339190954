import { getImageFields, getLinkFields } from ".";

export default (contentType: string) => `
  ... on ${contentType}_Pagelayout_FlexibleContent_TestimonialWithImage {
        author
        fieldGroupName
        heading
        semester
        testimonial
        ${getImageFields()}
    }
`;
