/** @format */

import {gql} from '@apollo/client';
import {getAcfPageLayoutFieldsQuery} from './acf';
import {getApolloClient} from './apollo-client';
import {getContentTypes} from './contentTypes';
import {seoFragment} from './fragments/seoFragment';

export const getContentTypeFromContentNode = async (
	id: string,
	idType = 'URI',
	subdomain = '',
) => {
	if (id === 'undefined') {
		id = '/';
	}

	console.log(
		'getContentTypeFromContentNode with id, idType, subdomain: ',
		id,
		idType,
		subdomain,
	);

	const client = getApolloClient(subdomain);
	try {
		const {data} = await client.query({
			query: gql`
				query GetContentTypeFromContentNode(
					$id: ID!
					$idType: ContentNodeIdTypeEnum!
				) {
					contentNode(id: $id, idType: $idType) {
						contentType {
							node {
								id
								graphqlPluralName
								graphqlSingleName
							}
						}
					}
				}
			`,
			variables: {
				id,
				idType,
			},
		});

		return data.contentNode ? data.contentNode.contentType.node : null;
	} catch (e) {
		console.error(
			`[contentNodes][getContentTypeFromContentNode]: ${e.message}`,
		);
		throw e;
	}
};

/* If pageLayout acf fields are not enabled on the post type, this will error */
export const getContentNode = async (
	id: string,
	idType = 'URI',
	asPreview = false,
	subdomain = '',
) => {
	const client = getApolloClient(subdomain);

	if (id === 'undefined') {
		id = '/';
	}

	id = id.replace(/,/g, '/');

	// console.log('getContentNode with id: ', id, idType, asPreview, subdomain);

	client.cache.reset();

	let contentType = await getContentTypeFromContentNode(id, idType, subdomain);
	if (!contentType) return null;
	// console.log('getContentNode contentType is: ', contentType);

	let {graphqlSingleName} = contentType;

	if (
		graphqlSingleName === 'page' ||
		graphqlSingleName === 'post' ||
		graphqlSingleName === 'newsItem'
	) {
		graphqlSingleName =
			graphqlSingleName.charAt(0).toUpperCase() + graphqlSingleName.slice(1);
	}

	const contentTypes = await getContentTypes();
	const contentTypesSingleNames: string[] = contentTypes
		.map((contentType: any) => {
			if (contentType.graphqlSingleName === 'page') return 'Page';
			if (contentType.graphqlSingleName === 'post') return 'Post';
			if (contentType.graphqlSingleName === 'newsItem') return 'NewsItem';
			return contentType.graphqlSingleName;
		})
		.filter(
			(contentTypesSingleName: string) =>
				contentTypesSingleName !== 'mediaItem',
	);

	// console.log('getContentNode contentTypesSingleNames is: ', contentTypesSingleNames);

	const acfFields = getAcfPageLayoutFieldsQuery(
		graphqlSingleName,
		contentTypesSingleNames,
	);

	if (!acfFields) console.error('acfFields query is missing');

	try {
		const {data} = await client.query({
			query: gql`
			${seoFragment}
        query GetContentNode(
          $id: ID!
          $idType: ContentNodeIdTypeEnum!
          $asPreview: Boolean!
        ) {
          contentNode(id: $id, idType: $idType, asPreview: $asPreview) {
            ... on ${graphqlSingleName} {
              id
              title
              uri
              link
               seo @skip(if: $asPreview) {
                 ...CoreSeoFields
               }
              pageLayout {
                fieldGroupName
                flexibleContent {
                  ${acfFields}
                }
              }
            }
          }
        }
      `,
			variables: {
				id,
				idType,
				asPreview,
			},
		});
		if (data?.contentNode) {
			// console.log('getContentNode data.contentNode is: ', data.contentNode);
			return data.contentNode;
		} else {
			// console.log('getContentNode data.contentNode is null', data);
			throw new Error('getContentNode data.contentNode is null');
		}
	} catch (e) {
		console.error(`[contentNodes][getContentNode]: ${e.message}`);
		console.error(JSON.stringify(e));
		throw e;
	}
};

export const getNewsItemContentNode = async (
	id: string,
	idType = 'URI',
	asPreview = false,
	subdomain = '',
) => {
	console.log('getNewsItemContentNode with id: ', id);
	const client = getApolloClient(subdomain);

	id = id.replace(/,/g, '/');

	client.cache.reset();

	let contentType = await getContentTypeFromContentNode(id, idType, subdomain);
	if (!contentType) return null;
	console.log('getNewsItemContentNode contentType is: ', contentType);

	let {graphqlSingleName} = contentType;

	if (graphqlSingleName === 'newsItem') {
		graphqlSingleName =
			graphqlSingleName.charAt(0).toUpperCase() + graphqlSingleName.slice(1);
	}

	const contentTypes = await getContentTypes();
	const contentTypesSingleNames: string[] = contentTypes
		.map((contentType: any) => {
			if (contentType.graphqlSingleName === 'newsItem') return 'NewsItem';
			return contentType.graphqlSingleName;
		})
		.filter(
			(contentTypesSingleName: string) =>
				contentTypesSingleName !== 'mediaItem',
		);

	const acfFields = getAcfPageLayoutFieldsQuery(
		graphqlSingleName,
		contentTypesSingleNames,
	);

	if (!acfFields) console.error('acfFields query is missing');

	try {
		const {data} = await client.query({
			query: gql`
			${seoFragment}
        query GetNewsItemContentNode(
          $id: ID!
          $idType: ContentNodeIdTypeEnum!
          $asPreview: Boolean!
        ) {
          contentNode(id: $id, idType: $idType, asPreview: $asPreview) {
            ... on NewsItem {
              id
              title
              uri
			  date
				slug
				excerpt
				featuredImage {
					node {
						sourceUrl
					}
				}
              link
               seo @skip(if: $asPreview) {
                 ...CoreSeoFields
               }
              pageLayout {
                fieldGroupName
                flexibleContent {
                  ${acfFields}
                }
              }
			  author {
					node {
						id
						name
						nicename
						nickname
						firstName
						lastName
						avatar {
							url
						}
					}
				}
				categories {
					edges {
						node {
							id
							name
						}
					}
				}
            }
          }
        }
      `,
			variables: {
				id,
				idType,
				asPreview,
			},
		});
		if (data?.contentNode) {
			console.log('getContentNode data.contentNode is: ', data.contentNode);
			return data.contentNode;
		} else {
			console.log('getContentNode data.contentNode is null', data);
			throw new Error('getContentNode data.contentNode is null');
		}
	} catch (e) {
		console.error(`[contentNodes][getContentNode]: ${e.message}`);
		console.error(JSON.stringify(e));
		throw e;
	}
};

export const getPostDetails = async (
	id: string,
	idType = 'URI',
	subdomain = '',
) => {
	const client = getApolloClient(subdomain);

	id = id.replace(/,/g, '/');

	try {
		const {data} = await client.query({
			query: gql`
				query GetPostDetails($id: ID!, $idType: ContentNodeIdTypeEnum!) {
					contentNode(id: $id, idType: $idType) {
						... on Post {
							author {
								node {
									name
									firstName
									lastName
								}
							}
							modifiedGmt
							title
							categories {
								nodes {
									name
									uri
								}
							}
						}
					}
				}
			`,
			variables: {
				id,
				idType,
			},
		});

		return data.contentNode;
	} catch (e) {
		console.error(`[contentNodes][getPostDetails]: ${e.message}`);
		throw e;
	}
};

export const getPostsByTaxonomy = async (
	filterArray: {
		field: string;
		terms: string[];
		taxonomy: string;
	}[],
	after: string,
	before: string,
	first: number | null,
	last: number | null,
	postType: string,
	subdomain = '',
) => {
	const client = getApolloClient(subdomain);

	let taxArray = filterArray
		.map(item => {
			return `{
        field: ${item.field}
        terms: ${JSON.stringify(item.terms)}
        taxonomy: ${item.taxonomy}
      }`;
		})
		.join(',');
	try {
		const {data} = await client.query({
			query: gql`
        query getPostsByTaxonomy(
          $after: String
          $before: String
          $first: Int
          $last: Int
        ) {
          ${postType}(
            where: {
              taxQuery: {
                taxArray: [${taxArray}]
                relation: OR
              }
            }
            first: $first
            last: $last
            after: $after
            before: $before
          ) {
            edges {
              node {
                id
                card {
                  cardviewExcerpt
                  cardviewHeading
                  cardviewLinkText
                  fieldGroupName
                  cardviewFeaturedImage {
                    altText
                    mediaDetails {
                      height
                      width
                    }
                    sourceUrl
                  }
                }
                uri
                slug
                terms {
                  nodes {
                    name
                  }
                }
              }
            }
            pageInfo {
              endCursor
              hasNextPage
              hasPreviousPage
              startCursor
            }
          }
        }
      `,
			variables: {
				after,
				before,
				first,
				last,
			},
		});
		return data[postType];
	} catch (e) {
		console.log(JSON.stringify(e));
		console.error(`[contentNodes][getPostsByTaxonomy]: ${e.message}`);
		throw e;
	}
};

export const getTerms = async (
	id: string,
	idType = 'URI',
	asPreview = false,
	subdomain = '',
) => {
	const client = getApolloClient(subdomain);
	let {graphqlSingleName} = await getContentTypeFromContentNode(
		id,
		idType,
		subdomain,
	);

	try {
		const {data} = await client.query({
			query: gql`

        query GetTerms(
          $id: ID!
          $idType: ContentNodeIdTypeEnum!
          $asPreview: Boolean!
        ) {
          contentNode(id: $id, idType: $idType, asPreview: $asPreview) {
            ... on ${graphqlSingleName} {
              terms {
                nodes {
                  id
                  name
                }
              }
            }
          }
        }
      `,
			variables: {
				id,
				idType,
				asPreview,
			},
		});

		return data.contentNode.terms?.nodes;
	} catch (e) {
		console.error(
			`[contentNodes][getArticleTerms]: ${e.message}. Make sure at least 1 taxonomy is set on the post type`,
		);
		console.log(JSON.stringify(e));
		throw e;
	}
};
