import { getImageFields, getLinkFields } from ".";

export default (contentType: string) => `
  ... on ${contentType}_Pagelayout_FlexibleContent_CtaCardLinks {
            fieldGroupName
            title
            cards{
                cardRepeater{
                    title
                    showLogo
                    links {
                        ${getLinkFields()}
                    }
                    backgroundGroup {
                        backgroundType
                        ${getImageFields()}
                        backgroundColour {
                            backgroundColour
                        }
                    }
                }
            }
   }
`;
