/** @format */

import accordionFields from './accordionFields';
import bioCardFields from './bioCardFields';
import ctaBannerFields from './ctaBannerFields';
import ctaCardFields from './ctaCardFields';
import ctaCardLinksFields from './ctaCardLinksFields';
import gridFields from './gridFields';
import heroBannerFields from './heroBannerFields';
import imageTextFields from './imageTextFields';
import richTextCtaFields from './richTextCtaFields';
import testimonialWithImageFields from './testimonialWithImageFields';
import videoLinkFields from './videoLinkFields';
import wysiwygFields from './wysiwygFields';
import tableFields from './tableFields';
import subNavigationFields from './subNavigationFields';
import imageGalleryFields from './imageGalleryFields';
import roomCarouselFields from './roomCarouselFields';
import formFields from './formFields';

export const getAcfPageLayoutFieldsQuery = (
	type: string,
	contentTypesSingleNames: string[],
) => {
	return `
      ${wysiwygFields(type)}
      ${videoLinkFields(type)}
      ${heroBannerFields(type)}
      ${imageTextFields(type)}
      ${ctaCardFields(type)}
      ${ctaCardLinksFields(type)}
      ${ctaBannerFields(type)}
      ${richTextCtaFields(type)}
      ${testimonialWithImageFields(type)}
      ${gridFields(type)}
      ${bioCardFields(type)}
      ${accordionFields(type)}
      ${tableFields(type)}
      ${subNavigationFields(type)}
      ${imageGalleryFields(type)}
      ${roomCarouselFields(type)}
      ${formFields(type)}
    `;
};

export const getImageFields = (fieldName = 'image') => `
  ${fieldName} {
    sourceUrl
    altText
    mediaDetails {
      height
      width
    }
  }
`;
export const getLinkFields = (fieldName = 'link') => `
  ${fieldName} {
      target
      title
      url
  }
`;
export const getButtonGroupFields = (fieldName = 'buttonGroup') => `
  ${fieldName} {
    buttons {
      buttonGroup {
        buttonColor
        style
        ${getLinkFields()}
      }
    }
  }
`;
