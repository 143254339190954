import { gql } from "@apollo/client";

export const seoFragment = gql`
  fragment CoreSeoFields on PostTypeSEO {
    opengraphTitle
    title
    metaKeywords
    focuskw
    twitterTitle
    twitterImage {
      sourceUrl
    }
    readingTime
    opengraphUrl
    opengraphType
    opengraphSiteName
    opengraphPublisher
    opengraphPublishedTime
    opengraphModifiedTime
    opengraphImage {
      sourceUrl
    }
    opengraphDescription
    opengraphAuthor
    metaRobotsNoindex
    metaRobotsNofollow
    metaDesc
    cornerstone
    canonical
    breadcrumbs {
      text
      url
    }
  }
`;
